import { reactive } from '@vue/composition-api'

export default {
  order: null,
  orders: [],
  customerInformationForm: {
    attachments: [],
  },
  dispatchAndReturnForm: reactive({}),
  eventInformationForm: {},
  orderItemsTable: {
    products: [],
  },
  orderItemsOtherChargesTable: {
    otherCharges: [],
  },
  orderItemsOtherChargesTableRef: null,
  onError: {},
  isLoading: false,
}
