export default {
  GET(state, data) {
    state.order = { ...data }
  },
  LIST(state, data) {
    state.orders = data
  },
  SET_CUSTOMER_INFORMATION_FORM(state, data) {
    state.customerInformationForm = { ...data }
  },
  LIST_ATTACHMENTS(state, data) {
    state.customerInformationForm.attachments = data
  },
  SET_DISPATCH_AND_RETURN_FORM(state, data) {
    state.dispatchAndReturnForm = { ...data }
  },
  SET_EVENT_INFORMATION_FORM(state, data) {
    state.eventInformationForm = { ...data }
  },
  SET_ORDER_ITEMS_RENTAL_TABLE(state, data) {
    if (data.products) {
      data.products.map(item => ({ ...item, amount: 0 }))
    }

    state.orderItemsTable = data
  },
  SET_ORDER_ITEMS_OTHER_CHARGES_TABLE(state, data) {
    state.orderItemsOtherChargesTable.otherCharges = data
  },
  SET_ORDER_ITEMS_OTHER_CHARGES_TABLE_REF(state, data) {
    state.orderItemsOtherChargesTableRef = data
  },
  SET_ON_ERROR(state, data) {
    state.onError = { ...data }
  },
  SET_IS_LOADING(state, status) {
    state.isLoading = status
  },
}
