import crudActions from '@/libs/storeConstActions'
import { customActionGet } from '@core/utils/utils'
import axios from '@/libs/axios'

const endpoint = 'orders/sub-rental'

// create-service-in-house
const Actions = crudActions(endpoint)
const getCustomer = ({ }, { id, queryParams = {} }) => customActionGet(`customers/${id}`, queryParams)
const deleteAttachment = (ctx, data) => axios.delete(`/attachments/${data.id}`)

const updateSubRental = (ctx, data) => {
  const id = data instanceof FormData ? data.get('id') : data.id
  return axios.post(`/${endpoint}/${id}`, data)
}
const voidSubRental = (ctx, data) => {
  const id = data instanceof FormData ? data.get('id') : data.id
  return axios.post(`/${endpoint}/${id}/void`, data)
}

export default {
  ...Actions,
  getCustomer,
  updateSubRental,
  voidSubRental,
  deleteAttachment,
}
