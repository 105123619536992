import { format } from 'date-fns'
import { mapDate } from '@core/utils/utils'

export default {
  getState: state => ({
    // Order Information
    customer_id: state.customerInformationForm.customer_id,
    billing_contact_id: state.customerInformationForm.billing_contact?.id,
    internal_order_reference: state.customerInformationForm.internal_order_reference,
    external_order_reference: state.customerInformationForm.external_order_reference,
    payment_term_id: state.customerInformationForm.payment_term_id?.id,
    attachments: state.customerInformationForm.attachments,

    // Dispatch and Return
    dispatch_warehouse_id: state.dispatchAndReturnForm.dispatch_warehouse_id?.id,
    dispatch_method_id: state.dispatchAndReturnForm.dispatch_method?.id,
    return_warehouse_id: state.dispatchAndReturnForm.return_warehouse_id?.id,
    returns_to_another_warehouse: state.dispatchAndReturnForm.order_routing_order_returns_to_another_warehouse ? '1' : '0',
    dispatch_without_transferring: state.dispatchAndReturnForm.order_routing_allow_dispatch_of_items_from_warehouse_of_origin ? '1' : '0',
    return_method_id: state.dispatchAndReturnForm.return_method?.id,
    internal_shipping_notes: state.dispatchAndReturnForm.internal_shipping_notes,
    dispatch_contact_id: state.dispatchAndReturnForm.dispatch_contact_id?.id,
    dispatch_address_id: state.dispatchAndReturnForm.dispatch_address_id?.id,
    return_address_id: state.dispatchAndReturnForm.return_address_id?.id,
    return_contact_id: state.dispatchAndReturnForm.return_contact_id?.id,
    return_phone: state.dispatchAndReturnForm.return_phone,
    return_email: state.dispatchAndReturnForm.return_email,

    // Sub-rental period
    rental_start: state.eventInformationForm?.period_rental_start_dispatch ? `${format(new Date(state.eventInformationForm?.period_rental_start_dispatch), 'yyyy-MM-dd')} ${state.eventInformationForm.period_rental_dispatch_start_time ? state.eventInformationForm.period_rental_dispatch_start_time.split(':').slice(0, 2).join(':') : '00:00'}` : '',
    rental_end: state.eventInformationForm?.period_rental_end_receive ? `${format(new Date(state.eventInformationForm?.period_rental_end_receive), 'yyyy-MM-dd')} ${state.eventInformationForm.period_rental_receive_end_time ? state.eventInformationForm.period_rental_receive_end_time.split(':').slice(0, 2).join(':') : '00:00'}` : '',
    event_reference: state.eventInformationForm?.event_reference,

    // Stock Items
    products: state.orderItemsTable.products.map(item => ({
      id: item.id,
      amount: item.amount,
      price: item?.price ? item?.price : item.rates?.[0]?.rate ?? '0',
      discount: item?.discount ?? 0,
      mpn: item?.mpn,
      available_on: mapDate(item?.available_on),
      supplier_sku: item?.supplier_sku,
    })),

    // Other Charges
    other_charges: state.orderItemsOtherChargesTable.otherCharges.map(({
      id, name, unit, subtotal, discount, price, taxes,
    }) => ({
      name, unit, subtotal, discount: discount ?? 0, id, price, tax: taxes ?? 0,
    })),
  }),
}
